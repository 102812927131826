.form-control-wide {
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
  border-radius: 8px;
}

.form-control-wide.bg-red {
  background-color: #ffcccc;
}

.title {
  font-size: 28px;
  font-weight: 800;
}

.input-field {
  position: relative;
  margin-bottom: 5px;
}

.input {
  margin-top: 15px;
  width: 100%;
  outline: none;
  border-radius: 8px;
  height: 45px;
  border: 1.5px solid #ecedec;
  background: transparent;
  padding-left: 10px;
}

.input:focus {
  border: 1.5px solid #04df333a;
}

.input-field .label {
  position: absolute;
  top: 25px;
  left: 15px;
  color: #ccc;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 2;
}

.input-field .input:focus ~ .label,
.input-field .input:not(:placeholder-shown) ~ .label,
.input-field .input.has-value ~ .label {
  top: 5px;
  left: 5px;
  font-size: 10.5px;
  color: #055316;
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
 
}

.input-field .input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-field .input:focus {
  border-color: #055316;
  outline: none;
}

.submit-btn {
  margin-top: 30px;
  height: 55px;
  background: #f2f2f2;
  border-radius: 11px;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(180deg, #04df3371 0%, #02300cc5 50%, #000000d5 100%);
  box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #02300cb7;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  cursor: pointer;
}

.submit-btn:hover {
  box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #04df333a;
}

.front-page {
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/*
.header {
  background-color: #4CAF50;
  color: white;
  padding: 20px;
}
  */

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.qr-code {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

.text-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.text {
  width: 45%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  text-align: left;
}

.text h2 {
  color: #4CAF50;
}

.text p {
  line-height: 1.6;
}

.side_pull {
  height: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  border-radius: 8px;
}


.product-label-form {
  margin: 20px;
}

h5 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #055316;
  outline: none;
}

.btn-primary {
  background: linear-gradient(180deg, #04df3371 0%, #02300cc5 50%, #000000d5 100%);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.btn-primary:hover {
  box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #04df333a;
}

.spinner-border {
  display: block;
  margin: 0 auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.modal-backdrop.show {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #21254D;
  width: "auto"; /* Adjust based on content size */
  max-width: 100%; /* Prevents modal from being too wide */
  max-height: 90vh; /* Limits the modal's height */
  overflow-y: auto; /* Enables vertical scrolling */
  margin: 20px; /* Distance from viewport edges */
  z-index: 1055;
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-100vh);
}

.modal-content.show {
  transform: translateY(0);
  opacity: 1;
}

.modal-body {
  overflow-y: auto;
  padding: 20px;
  margin: 20px 0; /* Adds space around the content */
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background: #f1f1f1; /* Optional; adds a distinct background to the footer */
  border-top: 1px solid #eee; /* Optional; adds a separator */
}

.delete-button {
  background: #B42318;
  color: #f95b5b;
  border-width: 1px;
  border-radius: 5px;
}

.delete-button:hover {
  background: #f7b3b3;
}
